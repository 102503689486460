@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #fafaff;
}

.spinner {
  width: 96px;
  height: 96px;
  border: 12px solid #0139ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
